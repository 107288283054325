.btnClick {
  background-color: cadetblue;
  color: white;
  border-radius: 6px;
  padding: 17px 35px;
  font-size: 1rem;
  transition: all 300ms;
  outline: none;
  font-weight: 700;
  display: inline-block;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
}
