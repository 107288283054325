html {
  scroll-behavior: smooth;
}

.remonter {
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 9;
  margin-right: -10%;
  transition: 300ms all;
}

.imgRemonter {
  width: 50px;
  border-radius: 50%;
  background-color: white;
  border: 3px solid white;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .imgRemonter {
    width: 100px;
  }

  .remonter {
    position: fixed;
    bottom: 5em;
    right: 3em;
    z-index: 9;
    margin-right: -30%;
    transition: 300ms all;
  }
}
