.accordion {
  border-bottom: 3px solid #f3f5f5;
}

.accordion:nth-last-child(1) {
  border-bottom: 0px solid white;
}

.width80 {
  width: 80%;
}

.closed {
  padding: 0;
  height: 0;
  overflow: hidden;
}

.padding0 {
  padding: 0;
}

.padding5-20 {
  padding: 5px 20px;
}

.open {
  animation: Open 0.4s ease-in forwards;
  overflow: hidden;
  padding: 5px 20px;
}

@keyframes Open {
  from {
    max-height: 0;
  }
  to {
    max-height: 500px;
  }
}

.width95 {
  width: 100%;
}

.cvOpen {
  animation: cvOpen 0.2s ease-in forwards;
}

@keyframes cvOpen {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.marginRight35 {
  margin-right: 35px;
}

.withDescription {
  display: flex;
  justify-content: center;
}

.accordion_img {
  max-width: 400px;
  max-height: 200px;
  border-radius: 5px;
  border: 5px solid orange;
}

.afterImgHover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.afterImgHover:hover:after {
  content: "+";
  font-size: 65px;
  color: black;
  border: 5px solid black;
  border-radius: 50%;
  padding: 10px;
  height: 50px;
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.afterImgHover:hover .accordion_img {
  filter: grayscale(60%);
  cursor: pointer;
}

.LMarginBottomLL {
  margin-bottom: 5px;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .accordion {
    border-bottom: 5px solid #f3f5f5;
  }

  .accordion_img {
    max-width: 400px;
    max-height: 400px;
    border-radius: 5px;
    border: 5px solid orange;
  }

  .LMarginBottomL {
    margin-bottom: 20px;
  }

  .LMarginBottomLL {
    margin-bottom: 15px;
  }
}
