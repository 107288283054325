.btn {
  background-color: #4175fc;
  color: white;
  border-radius: 6px;
  padding: 17px 35px;
  font-size: 1rem;
  transition: all 300ms;
  outline: none;
  font-weight: bold;
  display: inline-block;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
}

.btn:hover {
  color: #4175fc;
  background-color: white;
}

.btnHover:hover {
  color: white;
  cursor: pointer;
  background-color: #084af3;
}

.width100BoxBtn {
  width: 100%;
  box-sizing: border-box;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .btn {
    background-color: #4175fc;
    color: white;
    border-radius: 6px;
    padding: 25px 80px;
    font-size: 2.2rem;
    text-transform: uppercase;
    transition: all 300ms;
    outline: none;
    display: inline-block;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
  }
}
