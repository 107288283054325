.marginRightClient {
  margin-right: 5px;
}

.clientGap {
  gap: 50px;
}

.bold {
  font-weight: bold;
}

.width20 {
  width: 20%;
}

.noPaddingBottom {
  padding-bottom: 0;
}

@media all and (min-device-width: 780px) and (max-device-width: 1199px) {
  .width20 {
    width: 100%;
  }
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .width20 {
    width: 100%;
  }

  .gap50Mobile {
    margin: 50px 0 100px 50px;
    gap: 50px;
  }

  .leftMobile {
    text-align: left;
  }

  .marginRightClient {
    margin-right: 25px;
    font-size: 5rem;
  }
}
