.devisGrid {
  padding: 30px 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 40px 0;
}

.intoGrid3with {
  width: 100%;
}

.questionDevis {
  color: #e55d42;
}

.nocapi {
  text-transform: none;
}

.displayGridAvancementDevis {
  display: grid;
  border-radius: 0 30% 30% 0;
}

.heightSix {
  height: 8px;
  gap: 0 3px;
}

.enCours {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.enCours:first-child {
  background-color: #4175fc;
}

.enCours::after {
  content: "";
  background-color: #4175fc;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  animation: enCours 500ms ease-in;
}

.gap10 {
  padding: 25px 0;
  gap: 12px;
}

@keyframes enCours {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.petitPadding {
  padding: 20px 0;
}

.pasEnCours {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  border-radius: 20px;
}

@keyframes visibleOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .mobile100 {
    width: 100%;
  }

  .devisGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 0;
    margin: 50px 0;
    grid-gap: 70px 50px;
  }

  .gap10 {
    grid-gap: 30px 50px;
  }

  .heightSix {
    height: 20px;
    gap: 0 3px;
  }
}

@media all and (min-device-width: 780px) and (max-device-width: 1199px) {
  .devisGrid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding: 25px 0;
  }

  .gap10 {
    grid-gap: 30px 50px;
  }
}
