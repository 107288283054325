.btnDevis {
  background-color: white;
  color: #4175fc;
  border-radius: 6px;
  padding: 17px 10px;
  font-size: 1.1rem;
  text-align: center;
  transition: all 500ms;
  text-transform: none;
  outline: none;
  display: inline-block;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #4175fc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnDevis:hover {
  cursor: pointer;
  color: white;
  background-color: orangered;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .btnDevis {
    background-color: white;
    color: #4175fc;
    border-radius: 6px;
    padding: 25px 30px;
    font-size: 2.2rem;
    text-transform: none;
    transition: all 500ms;
    outline: none;
    display: inline-block;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #4175fc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnDevis:hover {
    background-color: white;
    color: #4175fc;
    cursor: pointer;
  }
}

@media all and (min-device-width: 780px) and (max-device-width: 1200px) {
  .btnDevis {
    padding: 25px 20px;
  }
}
