.btnSubmit {
  background-color: #4175fc;
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: "Alliance No.1", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: 0.06rem;
  border-radius: 5px;
  border: transparent;
  padding: 5px 15px;
  text-align: center;
  color: white;
  font-weight: bolder;
  width: 80%;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .btnSubmit {
    font-size: 2.3rem;
    line-height: 1.6;
    /* font-family: "The Antiqua B",Georgia,Droid-serif,serif; */
    font-family: "Alliance No.1", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    letter-spacing: 0.06rem;
    border-radius: 5px !important;
    padding: 15px 15px;
    width: 100%;
  }
}
