.marginCote {
  text-align: center;
  margin: 80px 40px;
  padding: 25px 25px 50px 25px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
