.imgPortfolio {
  max-width: 300px;
  max-height: 200px;
}

.afterImgHover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.afterImgHover:hover:after {
  content: "+";
  font-size: 65px;
  color: black;
  border: 5px solid black;
  border-radius: 50%;
  padding: 10px;
  height: 50px;
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.afterImgHover:hover .imgPortfolio {
  filter: grayscale(60%);
  cursor: pointer;
}

.padding40px {
  padding: 20px 0 60px 0;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .imgPortfolio {
    max-width: 80%;
    max-height: 500px;
  }
}
