.imgTeam {
  max-width: 200px;
  border-radius: 50%;
}

.teamMobile {
  display: none;
}

.gap20 {
  gap: 20px;
}

.gap100Team {
  gap: 100px;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .teamMobile {
    display: unset;
    border: 3px solid #f3f5f5;
    margin: 60px;
  }

  .gap20 {
    gap: 0;
  }

  .gap100Team {
    gap: 0px;
  }
}

@media all and (min-device-width: 780px) and (max-device-width: 1199px) {
  .gap20 {
    gap: 0;
  }
}

@media all and (min-device-width: 1200px) and (max-device-width: 1400px) {
  .grid4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }

  .grid4 .afterImgHover:nth-child(1n) {
    justify-content: right;
  }
  .grid4 .afterImgHover:nth-child(2n) {
    justify-content: left;
  }
}

.imgTemoignage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .imgTemoignage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
}

@media all and (min-device-width: 780px) and (max-device-width: 1199px) {
  .imgTemoignage {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}
