.customers {
  border-top: 3px solid #f5f5f5;
  border-bottom: 3px solid #f5f5f5;
}

.nowidthMax {
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  padding: 0 0 100px 0;
}

.customersGridA {
  margin: 0 auto;
}

.inGridCustomer {
  border-right: 3px solid #f5f5f5;
}

.inGridCustomer:first-child {
  border-left: 3px solid #f5f5f5;
}

.padding4A0px {
  padding: 60px;
}

.noGap {
  gap: 0;
}

.paddTop100 {
  padding-top: 100px;
}

@media all and (min-device-width: 200px) and (max-device-width: 1199px) {
  .padding80Mobile {
    padding: 0 80px 75px 80px;
  }

  .padding80Mobile,
  .padding80 {
    padding: auto 50px !important;
  }
}
