.blueColor {
  color: #4175fc;
}

.padding30 {
  padding: 30px;
}

.width80 {
  width: 80%;
}

.boxSadowww {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.radius5 {
  border-radius: 5px;
}

.widthSvg {
  width: 35px;
  height: 35px;
  margin-right: 30px;
}

@media all and (min-device-width: 200px) and (max-device-width: 1200px) {
  .widthSvg {
    display: none;
  }

  .width70 {
    width: 90%;
  }
}
