.noPaddingNoMarginX {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: none;
}

.padding80 {
  padding: 0 80px;
}

.width50 {
  width: 50%;
}

.max-Width100 {
  max-width: 100%;
}

.marginMobileAA {
  border: 1px solid #d4d4d4;
}

@media all and (min-device-width: 200px) and (max-device-width: 1199px) {
  .padding80Mobile {
    padding: 0 80px 75px 80px;
  }

  .padding80Mobile,
  .padding80 {
    padding: 0 50px;
  }

  .width50 {
    width: auto;
  }

  .max-Width100 {
    width: 100%;
    margin-bottom: 50px;
  }

  .flexReverse {
    flex-direction: column;
  }

  .paddingMobile {
    padding: 30px 0;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
  }

  .marginMobileAA {
    margin: 30px 0;
    border: 1px solid #d4d4d4;
  }
}
