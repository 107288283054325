.menu_mobile {
  display: none;
}

.menu_desktop {
  background-image: url("./img/imageFondPageAccueilUn.jpg");
  height: 100vh;
  background-size: cover;
  background-position: center center;
}

.opacityForMenu {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: #101218;
  opacity: 0.8;
}

.paddingTopMenu {
  padding: 20px 0px;
}

.ulGapMenu {
  gap: 32px;
}

.menu_content {
  position: relative;
  color: white;
  margin: 0 auto;
  max-width: 75%;
}

p {
  margin: 0;
}

.maxWidth150 {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
}

.width45 {
  width: 65%;
}

.contentInMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
}

.colorLogo {
  color: white;
  letter-spacing: 3px;
  word-spacing: 5px;
  font-size: 2rem;
  margin-left: 5px;
  color: white;
}

.menu_content,
.opacity0100 {
  animation: opacity0to100 1000ms ease-in-out forwards;
}

.originalFont {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@keyframes opacity0to100 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media all and (min-device-width: 780px) and (max-device-width: 1199px) {
  .colorLogo,
  .miniLogo {
    display: none;
  }

  .maxWidth1000 {
    max-width: 70%;
  }

  .imgTemoignage {
    width: 150px;
    height: 150px;
  }

  .grid3,
  .grid4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid4 .afterImgHover:nth-child(1n) {
    justify-content: right;
  }
  .grid4 .afterImgHover:nth-child(2n) {
    justify-content: left;
  }

  .grid4 {
    grid-gap: 60px;
  }

  .width45 {
    width: 65%;
  }

  .menu_content {
    max-width: 85%;
  }
}

@media all and (min-device-width: 1200px) and (max-device-width: 1400px) {
  .menu_content {
    max-width: 85%;
  }
}

ul a li.overMenu {
  padding: 5px !important;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .bc-imgMobile {
    background-image: url("./img/imgMobile.jpg");
    height: 700px;
    background-position: "";
    background-size: cover;
    box-sizing: border-box;
  }

  .opacityForMenu {
    height: 700px;
  }

  .slogan {
    margin-bottom: 180px;
    line-height: 80px;
  }

  .opacityForMenu {
    opacity: 0.7;
  }

  .openVisible {
    padding-left: 60px;
  }

  button[data-id^="bullet"] {
    display: none !important;
  }

  .marginForMobile {
    display: block;
    height: 400px;
  }

  .imgLogoMobileFuse {
    width: 120px;
  }

  .iconMenu {
    padding: 15px;
    /* border: 1px solid white; */
    border-radius: 5px;
    z-index: 99;
    top: 35px;
  }

  .croix {
    font-size: 80px;
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 999;
    color: white;
  }

  .fa-bars {
    font-size: 60px;
  }

  .menu_mobile {
    display: unset;
  }

  .menu_desktop {
    display: none;
  }

  .paddMenu {
    padding: 4rem;
    z-index: 99999;
  }

  .clickFerme {
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 999;
    height: 100vh;
    width: 100vw;
  }
  .ouvert {
    background-color: rgb(59, 59, 59);
    z-index: 9999;
    animation: OpenMenu 200ms ease-in forwards;
    position: fixed;
    top: 0;
    overflow: scroll;
    border-right: 1px solid white;
    height: 100vh;
    width: 70%;
  }

  .imgLogo {
    width: 150px;
  }

  @keyframes OpenMenu {
    from {
      margin-left: -71%;
    }
    to {
      margin-left: 0%;
    }
  }

  .ferme {
    background-color: rgb(59, 59, 59);
    overflow: hidden;
    top: 0;
    width: 70%;
    position: fixed;
    animation: CloseMenu 200ms ease-in forwards;
    height: 100vh;
  }

  @keyframes CloseMenu {
    from {
      margin-left: 0%;
    }
    to {
      box-sizing: border-box;
      margin-left: -71%;
    }
  }

  .deplier {
    margin-left: 60px;
    margin-bottom: 45px;
  }

  .deplier li {
    list-style-type: disc;
  }

  .Margin_bottom50 {
    margin: 50px 20px 0 20px;
  }

  .Margin_bottom100 {
    margin: 0px 20px 100px 20px;
  }

  .width100 {
    width: 100%;
    margin-bottom: 8px;
  }

  .itemMenu {
    margin: 45px 20px;
  }

  .itemSousMenu {
    margin: 30px 20px;
  }

  .iconLocation {
    font-size: 3.2rem;
    font-weight: 500;
  }

  .separator {
    width: 100%;
    background-color: white;
    height: 2px;
    border: none;
  }
}
