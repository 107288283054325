.stepFont {
  font-size: 30px;
  width: 1em;
  height: 1em;
  border: 3px solid black;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .stepFont {
    font-size: 50px;
    width: 90px;
    height: 90px;
  }
}
