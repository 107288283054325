.menu_desktopOther {
  background-image: url("./img/imageFondPageAccueil.jpg");
  height: 50vh;
  min-height: 500px;
  background-size: cover;
  background-position: center center;
}

.opacityForMenuOther {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: #101218;
  opacity: 0.8;
}

.centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
}

.contact,
.a_propos,
.accueil,
.btnOndevis {
  color: burlywood !important;
}

.topMarginBlockMenu {
  top: 60%;
}

.maxwidth33 {
  width: 33%;
}

.borderBottom5Bleu {
  border-radius: 0 0 5px 5px;
  border-bottom: 5px solid #4175fc;
}

.padd35Marg15 {
  padding: 35px;
  margin: 15px;
}

.blockIntoAboutUs {
  position: relative;
  box-shadow: 0px 0px 40px 0px rgba(36, 42, 86, 0.15);
}

.maxWidth1200 {
  max-width: 1200px;
}

.depasse {
  margin-top: -100px;
}

.titleIntoAboutUs {
  width: 50px;
  background-color: #4175fc;
  height: 7px;
}

.desktopNone {
  display: none;
}

.chevronStyleFaisons {
  font-size: 36px;
  color: #4175fc;
  transform: rotate(90deg);
}

.chevronStyleFaisons2 {
  font-size: 36px;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .noneMobile {
    display: none;
  }

  .padd030mobile {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .desktopNone {
    display: unset;
  }

  .titleIntoAboutUs {
    width: 150px;
    height: 10px;
  }

  .menu_desktopOther {
    display: none;
  }

  .chevronStyleFaisons,
  .chevronStyleFaisons2 {
    font-size: 80px;
  }
}
