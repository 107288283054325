.imgAideSvg {
  width: 60px;
  height: 60px;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .imgAideSvg {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }

  .imgTemoignage {
    width: 200px;
    height: 100px;
  }
}
